import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../style/VideosPage.css';

const VideosPage = () => {
    const { userId } = useParams();
    const navigate = useNavigate();

    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}/videos`);
                const data = await response.json();

                if (data && data.videos) {
                    const videosWithFullPaths = data.videos.map(video => ({
                        ...video,
                        video_path: video.video_path ? video.video_path.replace('/videos-output', process.env.REACT_APP_S3_URL) : null
                    }));
                    setVideos(videosWithFullPaths.reverse());
                }
            } catch (err) {
                setError('Failed to load videos.');
            } finally {
                setLoading(false);
            }
        };

        fetchVideos();

        const interval = setInterval(() => {
            fetchVideos();
        }, 1000); // Poll every 5 seconds

        return () => clearInterval(interval);
    }, [userId]);


    const handleDownload = (videoPath) => {
        fetch(videoPath)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = videoPath.split('/').pop();
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => console.error('Download error:', error));
    };

    if (loading) {
        return <div className="loading">Loading videos...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <div className="container">
            <h2 className="title">Your Videos</h2>
            <div className="cta">
                <button className="button" onClick={() => navigate('/upload')}>
                    Create New Video
                </button>
            </div>
            <div className="videos-grid">
                {videos.map(video => (
                    <div className="video-wrapper" key={video._id}>
                        {video.status !== 'completed' ? (
                            <div className="video-placeholder">
                                <div className="spinner"></div>
                                <span>Processing...</span>
                            </div>
                        ) : (
                            <AsyncVideo videoPath={video.video_path} handleDownload={handleDownload} />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

const AsyncVideo = ({ videoPath, handleDownload }) => {
    const [exists, setExists] = useState(false);

    useEffect(() => {
        const checkExistence = async () => {
            const exists = await checkVideoExistence(videoPath);
            setExists(exists);
        };

        checkExistence();
    }, [videoPath]);


    const checkVideoExistence = async (videoPath) => {
        try {
            const response = await fetch(videoPath, { method: 'GET', mode: 'cors' });
            console.log('response', response);
            return response.ok;
        } catch (err) {
            return false;
        }
    };

    const handleVideoError = (event) => {
        event.target.style.display = 'none';
    };


    if (!exists) {
        return (
            <div className="video-placeholder">
                <div className="spinner"></div>
                <span>Processing...</span>
            </div>
        );
    }

    return (
        <div className="video-container">
            <video
                className="video"
                autoPlay
                loop
                muted
                playsInline
                onError={handleVideoError}
            >
                <source src={videoPath} type="video/mp4" />
            </video>
            <button 
                className="download-button"
                onClick={() => handleDownload(videoPath)}
            >
                ⬇
            </button>
        </div>
    );
};

export default VideosPage;

import React, { useEffect } from 'react';
import '../style/TestPage.css';

const TestPage = () => {



    return (
        <div className="page upload">
            <div className="headline">
                <h1>Test Page</h1>
            </div>
            <div className="middle">
                {/*
                <div className="good-examples">
                    <h3>✅ Good Examples</h3>
                    <div className="description">Front standing, full body shot, no angles</div>
                    <div className="examples" style={{ backgroundImage: 'url(/examples/good-examples.jpg)', paddingBottom: '52%' }}>
                    </div>
                </div>

                <div className="bad-examples">
                    <h3>❌ Bad Examples</h3>
                    <div className="description">Cropped, selfie, multiple people, too far</div>
                    <div className="examples" style={{ backgroundImage: 'url(/examples/bad-examples.jpg)', paddingBottom: '32%' }}>
                    </div>
                </div>
                */}
                <div className={`preview-container`}>
                    <img
                        src="/examples/IMG_9081.jpg"
                        alt="Preview"
                        className='preview'
                        id="preview-image"
                    />
                </div>
            </div>
            <div className="bottom">
                <button className="button">Test Button</button>
            </div>
        </div>
    );
}

export default TestPage;

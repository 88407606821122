import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Marquee from 'react-marquee-slider';

import '../style/CheckoutPage.css';



const paymentPlans =
{
  plan_1: { "title": "1 random dance", "description": "You get one video of the person dancing.", "price": 1.99 },
  plan_2: { "title": "10 dance videos", "description": "You get 10 dance videos each with a unique style.", "price": 8.99 },
}



const Checkbox = ({ size = 32, color = 'rgb(81, 255, 160)', checked = false }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        fill={checked ? color : 'none'}
        stroke={checked ? color : '#444'}
        strokeWidth="2"
      />
      {checked && (
        <path
          d="M7 12l3 3 7-7"
          stroke="#444"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};



const CheckoutPage = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { image } = location.state || {};


  const [selectedPlan, setSelectedPlan] = useState(process.env.REACT_APP_DEFAULT_PAYMENT_PLAN);
  const [displayEmail, setDisplayEmail] = useState(true);
  const [email, setEmail] = useState('');

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentRequestAvailable, setPaymentRequestAvailable] = useState(false);
  const [cardPaymentVisible, setCardPaymentVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const emailInputRef = useRef(null);


  //
  //  make sure we have a valid image
  //
  useEffect(() => {

    if (!image) {
      navigate('/upload');
    }

  }, [image, navigate]);


  // 
  //  focus on email input when displayed
  //
  useEffect(() => {
    if (displayEmail && emailInputRef.current) {
      console.log(`Focusing on email input`);
      emailInputRef.current.focus();
    }
  }, [displayEmail]);


  //
  useEffect(() => {
    if (cardPaymentVisible) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        console.log(`Focusing on card element`);
        cardElement.focus();
      }
    }
  }, [cardPaymentVisible, elements]);


  useEffect(() => {
    console.log(paymentPlans[selectedPlan]);
  }, [selectedPlan]);


  //
  // fetch stripe payment request
  //
  useEffect(() => {

    if (!stripe || !elements) {
      return;
    }

    const fetchPaymentRequest = async () => {
      const paymentRequest = await stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: paymentPlans[selectedPlan].price * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      paymentRequest.canMakePayment().then(result => {
        if (result && result.applePay) {
          setPaymentRequest(paymentRequest);
          setPaymentRequestAvailable(true);
        }
      });
    };

    fetchPaymentRequest();
  }, [stripe, elements, selectedPlan]);


  const handleEmailSubmit = () => {
    setDisplayEmail(false);
  }

  //
  const createPaymentIntent = async () => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_PAYMENT_INTENT_URL}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, amount: paymentPlans[selectedPlan].price * 100 }),
    });

    const { client_secret } = await response.json();

    return client_secret;
  }


  const handlePaymentClick = async () => {

    if (!stripe || !elements) {
      console.error('Stripe.js has not loaded yet.');
      return;
    }

    if (paymentRequestAvailable) {

      setLoading(true);
      paymentRequest.show();
      paymentRequest.on('paymentmethod', async (event) => {

        const clientSecret = await createPaymentIntent();

        const ret = await stripe.confirmCardPayment(clientSecret, {
          payment_method: event.paymentMethod.id,
        });

        if (ret.error) {
          event.complete('fail');
          alert(ret.error.message);
        }

        else {
          event.complete('success');
          console.log('Payment succeeded');
          fetchRedirectUrl();
        }

        setLoading(false);
      });
    }

    else {
      setCardPaymentVisible(true);
    }
  }

  const handleSubmitCardPayment = async () => {
    setLoading(true);

    const cardElement = elements.getElement(CardElement);
    const clientSecret = await createPaymentIntent();

    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          email: email,
        },
      },
    });

    if (error) {
      console.error('Payment failed:', error);
      alert(error.message);
    } else {
      console.log('Payment succeeded');
      fetchRedirectUrl();
    }

    setLoading(false);
  };


  const fetchRedirectUrl = async () => {
    try {

      const formData = new FormData();
      formData.append('email', email);
      formData.append('plan', selectedPlan);
      formData.append('image_ref', image);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/dance-please`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok || response.status !== 200) {
        throw new Error('Failed to upload. Please try again.');
      }

      const { user_id } = await response.json();

      if (!user_id) {
        throw new Error('Failed to fetch user ID. Please try again.');
      }

      navigate(`/videos/${user_id}`);
    }

    catch (error) {
      console.error('Error fetching redirect URL:', error);
      alert('An error occurred. Please try again.');
    }
  }

  if (!image) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="checkout email" style={{ display: displayEmail ? 'flex' : 'none' }}>
        <div className="email-form">
          <h2>Where do we send it?</h2>
          <input
            type="email"
            placeholder="Enter your email"
            className="email-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            ref={emailInputRef}
            required
          />
          <button
            onClick={handleEmailSubmit}
            // enable only if valid email regex
            disabled={!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)}
          >
            Continue
          </button>
        </div>
      </div>

      <div className="checkout" style={{ display: displayEmail ? 'none' : 'flex' }}>
        <div>
          <h1>Unlock your dance videos 🪩</h1>
          <p className="notice">Important notice: typically, about 20-30% of the videos meet our quality standards. Therefore, generating more variations improves the likelihood of achieving better results.</p>
          <div className="plans">
            {Object.entries(paymentPlans).map(([id, plan]) => (
              <div className={`plan ${selectedPlan === id ? 'active' : ''}`} onClick={() => setSelectedPlan(id)}>
                <div className="checkbox">
                  <Checkbox checked={selectedPlan === id} />
                </div>
                <div className="plan-content">
                  <div className="plan-title">{plan.title}</div>
                  <div className="plan-desc">{plan.description}</div>
                </div>
                <div className="price">${plan.price}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="checkout-form">
          <p>One-time payment, no hidden fees</p>
          {cardPaymentVisible && (
            <>
              <div className="card-payment">
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '18px',
                        color: '#FFF',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </div>
              <button
                className="button"
                onClick={handleSubmitCardPayment}
                disabled={!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)}
              >{loading ? <div className="spinner-checkout"></div> : 'Continue'}</button>
            </>
          ) || (
              <button
                className="button"
                onClick={handlePaymentClick}
                disabled={!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)}
              >{loading ? <div className="spinner-checkout"></div> : 'Access Videos 🔒'}</button>
            )}
        </div>
        <div className="testimonials">
          <h2>23k+ videos generated</h2>
          <Marquee velocity={25} minScale={0.75}>
            <div className="testimonial">
              <img src="/testimonials/1.gif" alt="Testimonial 1" />
            </div>
            <div className="testimonial">
              <img src="/testimonials/2.gif" alt="Testimonial 1" />
            </div>
            <div className="testimonial">
              <img src="/testimonials/3.gif" alt="Testimonial 1" />
            </div>
            <div className="testimonial">
              <img src="/testimonials/4.gif" alt="Testimonial 1" />
            </div>
            <div className="testimonial">
              <img src="/testimonials/1.gif" alt="Testimonial 1" />
            </div>
            <div className="testimonial">
              <img src="/testimonials/2.gif" alt="Testimonial 1" />
            </div>
            <div className="testimonial">
              <img src="/testimonials/3.gif" alt="Testimonial 1" />
            </div>
            <div className="testimonial">
              <img src="/testimonials/4.gif" alt="Testimonial 1" />
            </div>
          </Marquee>
        </div>
      </div>
    </>
  );
}

export default CheckoutPage;

import React from 'react';
import { Link } from 'react-router-dom';
import '../style/HomePage.css';

const HomePage = () => {
  return (
    <div className="home">
      <video 
        className="bg-video"
        src="home-video.mp4" 
        autoPlay loop muted playsInline
      ></video>
      <div className="bottom">
        <h1>Dance, please !</h1>
        <p className="tagline">Make anyone dance by simply uploading a picture of them</p>
        <Link to="/upload">
          <button className="button">Make someone dance</button>
        </Link>
      </div>
    </div>
  );
}

export default HomePage;

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PoseLandmarker, FilesetResolver, DrawingUtils } from 'https://cdn.skypack.dev/@mediapipe/tasks-vision@0.10.0';

import '../style/UploadPage.css';

const UploadPage = () => {

    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const [uploadError, setUploadError] = useState('');
    const [progress, setProgress] = useState(0);

    const [uploadStage, setUploadStage] = useState('');
    const [uploadComplete, setUploadComplete] = useState(false);
    const [poseLandmarker, setPoseLandmarker] = useState(null);

    const stages = [
        'SENDING IMAGE...',
        'ANALYZE POSTURE...',
        'GENERATE MOVES...',
        'ADDING MUSIC...',
        'ALMOST READY...'
    ];

    useEffect(() => {
        const loadPoseLandmarker = async () => {
            const vision = await FilesetResolver.forVisionTasks(
                'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm'
            );
            const landmarker = await PoseLandmarker.createFromOptions(vision, {
                baseOptions: {
                    modelAssetPath: 'https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_lite/float16/1/pose_landmarker_lite.task',
                    delegate: 'GPU'
                },
                runningMode: 'IMAGE',
                numPoses: 2
            });
            setPoseLandmarker(landmarker);
        };
        loadPoseLandmarker();
    }, []);

    

    useEffect(() => {
        if (selectedFile && isUploading && poseLandmarker) {
            const imageElement = document.getElementById('preview-image');
            imageElement.onload = async () => {
                const previewContainer = document.querySelector('.preview-container');

                // Clear any previous canvas
                const previousCanvas = previewContainer.querySelector('canvas');
                if (previousCanvas) {
                    previewContainer.removeChild(previousCanvas);
                }

                // Create and position the canvas relative to the image
                const canvas = document.createElement('canvas');
                canvas.width = imageElement.width;
                canvas.height = imageElement.height;
                canvas.style.position = 'absolute';
                canvas.style.top = `${imageElement.offsetTop}px`;
                canvas.style.left = `${imageElement.offsetLeft}px`;
                canvas.style.width = `${imageElement.width}px`;
                canvas.style.height = `${imageElement.height}px`;

                const ctx = canvas.getContext('2d');
                const drawingUtils = new DrawingUtils(ctx);
                const result = await poseLandmarker.detect(imageElement);
                result.landmarks.forEach((landmark) => {
                    drawingUtils.drawConnectors(landmark, PoseLandmarker.POSE_CONNECTIONS, {
                        color: 'white',
                        lineWidth: 2
                    });
                });
                previewContainer.appendChild(canvas);
            };
        }
    }, [selectedFile, isUploading, poseLandmarker]);



    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsUploading(false);
        setUploadError('');
        setProgress(0);
        setUploadComplete(false);
    };


    const handleSubmit = async () => {

        setIsUploading(true);
        setUploadError('');
        setProgress(0);
        setUploadStage(stages[0]);

        const intervalTime = 3000;
        const totalStages = stages.length;
        let currentStageIndex = 0;
    
        const timer = setInterval(() => {
            currentStageIndex++;
            if (currentStageIndex < totalStages) {
                setUploadStage(stages[currentStageIndex]);
                setProgress(((1+currentStageIndex) / totalStages) * 100);
            } else {
                clearInterval(timer);
                setUploadComplete(true);
                navigate("/checkout", { state: { image: selectedFile } });
            }
        }, intervalTime);

    };



    return (
        <div className="page upload" style={selectedFile ? { backgroundColor: 'rgb(81, 255, 160)' } : {}}>

            <div className="headline">
                <h1 style={selectedFile ? { color: 'black' } : {}}>{selectedFile ? 
                    (isUploading ? uploadStage : 'Are you Sure ?') : 'Upload Your Pic'}</h1>
            </div>

            <div className="middle">

                {!selectedFile && (
                    <>
                        <div className="good-examples">
                            <h3>✅ Good Examples</h3>
                            <div className="description">Front standing, full body shot, no angles</div>
                            <div className="examples" style={{ backgroundImage: 'url(/examples/good-examples.jpg)', paddingBottom: '52%' }}>
                            </div>
                        </div>

                        <div className="bad-examples">
                            <h3>❌ Bad Examples</h3>
                            <div className="description">Cropped, selfie, multiple people, too far</div>
                            <div className="examples" style={{ backgroundImage: 'url(/examples/bad-examples.jpg)', paddingBottom: '32%' }}>
                            </div>
                        </div>
                    </>
                )}


                {selectedFile && (
                    <div className={`preview-container ${isUploading ? 'uploading' : ''}`}>
                        <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="Preview"
                            className='preview'
                            id="preview-image"
                        />
                    </div>
                )}

            </div>


            <div className="bottom">

                {uploadError && <div style={{ color: 'red', marginTop: '10px' }}>{uploadError}</div>}

                {selectedFile && (
                    <>
                        {isUploading ? (
                            <div style={{ padding: '0 2rem' }}>
                                <ProgressBar progress={progress} />
                            </div>
                        ) : (
                            <button className='button-validate' onClick={handleSubmit}>Seems Good ✅</button>
                        )}
                    </>
                )}

                <input
                    type="file"
                    id="file-upload"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    disabled={isUploading}
                />

                <label
                    htmlFor="file-upload"
                    className={selectedFile ? 'button-change' : 'button'}
                    style={{
                        cursor: isUploading ? 'default' : 'pointer',
                        display: isUploading ? 'none' : 'inline-block'
                    }}
                >
                    {selectedFile ? 'No, choose another one' : 'Upload Image'}
                </label>

            </div>
        </div>
    );
};

const ProgressBar = ({ progress }) => (
    <div style={{ width: '100%', backgroundColor: '#FFF', borderRadius: '12px' }}>
        <div
            style={{
                width: `${progress}%`,
                height: '24px',
                backgroundColor: '#000',
                borderRadius: '12px',
                transition: 'width 2s ease-in-out',
            }}
        />
    </div>
);

export default UploadPage;
